import get from 'lodash/get';
import React from 'react';
import { Tab } from 'react-admin';

import EmailListField from '../../tickets/inputs/EmailListField';
import TicketReferenceManyField from './TicketReferenceManyField';

const TabBookings = (props) => (
  <Tab label='Valid Bookings' {...props}>
    <TicketReferenceManyField
      addLabel
      basePath={get(props, 'location.basePath', '')}
      filter={{
        status_ne: ['cancelled', 'waitlisted'],
      }}
      hideCancellationFields
      hideWaitinglist
      label='Bookings (valid, not cancelled, not on waitinglist)'
      perPage={50}
    />
    <EmailListField filter={{ status_ne: ['cancelled', 'waitlisted'] }} perPage={50} />
  </Tab>
);

export default TabBookings;
